<template>
  <div>
    <div class="card mb-5 mt-sm-5">
      <div class="card-body">
        <div v-if="filtersConfig.length" class="row">
          <div class="col-md-6">
            <MultiselectFormGroup
                class="app-view__form-group"
                :label="computedBanksFilter.title"
                :value="computedBanksFilter.options.filter(el => filters.bank_id.includes(el.id))"
                :options="computedBanksFilter.options"
                :multiple="true"
                @select="filters.bank_id.push($event.id)"
                @remove="filters.bank_id = filters.bank_id.filter(el => el !== $event.id)"
            />
          </div>
          <div class="col-md-6 d-flex">
            <DatetimePickerFormGroup
                class="w-50 mr-1"
                :label="computedPayDateFilter.title"
                :placeholder="$t('placeholder.from')"
                :value="filters.pay_date.from"
                :with-icon="false"
                :max-datetime="filters.pay_date.to"
                allow-clear
                @change="filters.pay_date.from = $event"
            />
            <DatetimePickerFormGroup
                class="w-50"
                :value="filters.pay_date.to"
                :placeholder="$t('placeholder.to')"
                :with-icon="false"
                :min-datetime="filters.pay_date.from"
                allow-clear
                @change="filters.pay_date.to = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-7">
      <div class="card-body p-0">
        <BootstrapVueTable
            request-url="api/transactions/table"
            module-name="transactions"
            :filters="computedFilters"
            :update-data="updateTableFlag"
            with-pagination
            with-footer
            @fileClicked="downloadFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import BootstrapVueTable from '@/components/elements/tables/BootstrapVueTable';
import DatetimePickerFormGroup from '@/components/elements/form-groups/DatetimePickerFormGroup';
import InputFormGroup from '@/components/elements/form-groups/InputFormGroup';
import MultiselectFormGroup from '@/components/elements/form-groups/MultiselectFormGroup';

export default {
  components: {
    BootstrapVueTable,
    DatetimePickerFormGroup,
    InputFormGroup,
    MultiselectFormGroup
  },
  data: () => ({
    filters: {
      bank_id: [],
      pay_date: {
        from: null,
        to: null,
      },
    },
    filtersConfig: {},
    filtersLoaded: false,
    updateTableFlag: false,
  }),
  async created() {
    const DEBOUNCE_TIME = 400;
    this.debouncedUpdate = _.debounce(this.debouncedUpdate, DEBOUNCE_TIME);
  },
  async beforeMount() {
    this.filtersConfig = await this.$store.dispatch('tableStore/GET_FILTERS', 'transactions');
  },
  computed: {
    computedBanksFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'bank') : null;
    },
    computedPayDateFilter() {
      return this.filtersConfig.length ? this.filtersConfig.find(el => el.code === 'pay_date') : null;
    },
    computedFilters() {
      let result = {};

      for (let key in this.filters) {
        if (this.filters.hasOwnProperty(key) && this.filters[key]) {
          if (Array.isArray(this.filters[key]) && !this.filters[key].length) continue;
          if (!Array.isArray(this.filters[key]) && typeof this.filters[key] === 'object' && !this.filters[key].from && !this.filters[key].to) continue;
          result[key] = this.filters[key];
        }
      }

      return result;
    }
  },
  watch: {
    filtersConfig(data) {
      if (data) {
        setTimeout(() => {
          this.filtersLoaded = true;
        }, 100);
      }
    },
    filters: {
      handler() {
        if (this.filtersLoaded) {
          this.updateTableFlag = true;
          setTimeout(() => {
            this.updateTableFlag = false;
          }, 1000);
        }
      },
      deep: true
    },
  },
  methods: {
    debouncedUpdate(key, value) {
      if (!this.filtersLoaded) return false;
      this.$set(this.filters, key, value);
    },
    async downloadFile(file) {
      await this.$store.dispatch('transactionsStore/DOWNLOAD_FILE', file.id);
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  position: relative;
  margin-bottom: 0 !important;

  & .form-group__label {
    position: absolute;
    top: -10px;
    left: 7px;
    background: #fff;
    z-index: 2;
  }
}
</style>
